import React, { Component } from "react";
import { Admin, Resource } from "react-admin";
import { createBrowserHistory as createHistory } from "history";
import UserIcon from "@material-ui/icons/Group";
import BookIcon from "@material-ui/icons/Book";
import {
  AccountTreeSharp,
  Info,
  Collections,
  Public,
  MovieRounded,
  StarSharp,
  HomeSharp,
} from "@material-ui/icons";

import dataProvider from "./authentication/dataProvider";
import AuthProvider from "./authentication/authProvider";
import Dashboard from "./components/dashoboard/dashboard";
import NotFound from "./components/notFound";
import UserList from "./components/users/users";
import UserShow from "./components/users/user.view";
import UserCreate from "./components/users/user.create";
import UserEdit from "./components/users/user.edit";
import CategoriesList from "./components/categories/categories";
import CategoryShow from "./components/categories/category.view";
import CategoryCreate from "./components/categories/category.create";
import CategoryEdit from "./components/categories/category.edit.jsx";
import NFTList from "./components/nft/nfts";
import NFTCreate from "./components/nft/nft.create";
import NFTShow from "./components/nft/nft.view";
import NFTEdit from "./components/nft/nft.edit";
import BannerList from "./components/banner/banners";
import BannerCreate from "./components/banner/banner.create";
import BannerShow from "./components/banner/banner.view";
import BannerEdit from "./components/banner/banner.edit";
import InfoList from "./components/info/infos";
import InfoShow from "./components/info/info.view";
import InfoCreate from "./components/info/info.create";
import InfoEdit from "./components/info/info.edit";

import ProfileInfoList from "./components/profile-info/infos";
import ProfileInfoShow from "./components/profile-info/info.view";
import ProfileInfoCreate from "./components/profile-info/info.create";
import ProfileInfoEdit from "./components/profile-info/info.edit";

import HallFrameInfoList from "./components/hall-frame-info/infos";
import HallFrameInfoShow from "./components/hall-frame-info/info.view";
import HallFrameInfoCreate from "./components/hall-frame-info/info.create";
import HallFrameInfoEdit from "./components/hall-frame-info/info.edit";
import CollectionList from "./components/collection/collections";
import CollectionCreate from "./components/collection/collection.create";
import CollectionShow from "./components/collection/collection.view";
import CollectionEdit from "./components/collection/collection.edit";
import PopularNFTs from "./components/popular-nft/popular.nft";
import PopularNFTShow from "./components/popular-nft/popular-nft.view";
import PopularCollections from "./components/popular-collection/popular-collection";
import PopularCollectionShow from "./components/popular-collection/popular-collection.view";
import MyLoginPage from "./components/login";

const history = createHistory();

class App extends Component {
  render() {
    return (
      <Admin
        title="React Admin"
        history={history}
        authProvider={AuthProvider}
        dataProvider={dataProvider}
        dashboard={Dashboard}
        NotFound={NotFound}
        loginPage={MyLoginPage}
      >
        <Resource
          name="users"
          icon={UserIcon}
          list={UserList}
          show={UserShow}
          edit={UserEdit}
          create={UserCreate}
          title="List of Users"
        />
        <Resource
          name="categories"
          list={CategoriesList}
          show={CategoryShow}
          create={CategoryCreate}
          edit={CategoryEdit}
          title="List of Categories"
        />
        <Resource
          name="nft"
          icon={AccountTreeSharp}
          show={NFTShow}
          list={NFTList}
          create={NFTCreate}
          edit={NFTEdit}
          title="List of NFT's"
        />
        <Resource
          name="banners"
          icon={BookIcon}
          list={BannerList}
          show={BannerShow}
          create={BannerCreate}
          edit={BannerEdit}
          title="Banner"
        />
        <Resource
          name="info"
          icon={Info}
          list={InfoList}
          show={InfoShow}
          create={InfoCreate}
          edit={InfoEdit}
          title="Info"
        />
        <Resource
          name="profile-info"
          icon={HomeSharp}
          list={ProfileInfoList}
          show={ProfileInfoShow}
          create={ProfileInfoCreate}
          edit={ProfileInfoEdit}
          title="Profile Info"
        />
        <Resource
          name="hall-frame-info"
          icon={StarSharp}
          list={HallFrameInfoList}
          show={HallFrameInfoShow}
          create={HallFrameInfoCreate}
          edit={HallFrameInfoEdit}
          title="Hall Of Frame Info"
        />
        <Resource
          name="collections"
          icon={Collections}
          list={CollectionList}
          create={CollectionCreate}
          show={CollectionShow}
          edit={CollectionEdit}
          title="Collection"
        />
        <Resource
          name="popular-nft"
          icon={Public}
          list={PopularNFTs}
          show={PopularNFTShow}
          title="Popular NFT"
        />
        <Resource
          name="popular-collection"
          icon={MovieRounded}
          list={PopularCollections}
          show={PopularCollectionShow}
          title="Popular Collection"
        />
      </Admin>
    );
  }
}

export default App;
