import { useState, useEffect } from "react";
import fileToArrayBuffer from "file-to-array-buffer";
import {
  SimpleForm,
  ImageField,
  ImageInput,
  SelectArrayInput,
  TextInput,
  required,
  useRedirect,
  useNotify,
} from "react-admin";
import { services } from "../../services";
import { actions } from "../../actions";
import ipfs from "../../config/ipfs";
import Loader from "../loading";

const UserCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [loading, setLoading] = useState(false);
  const [callAPI, setCallAPI] = useState(false);
  const [buffer, setBuffer] = useState(null);
  const [categories, setCategories] = useState([]);
  const [params, setParams] = useState({
    name: null,
    email: null,
    bio: null,
    category: null,
    walletAddress: null,
    profile: null,
    username: null,
  });

  useEffect(() => {
    async function getCateogries() {
      const categories = await actions.getCategoriesForAdmin();
      setCategories(categories.data);
    }
    getCateogries();

    async function createUser() {
      setLoading(true); // start loader
      const request = services.post(`/user/addNewUserByAdmin`, params);
      request
        .then((response) => {
          if (response.status < 200 || response.status >= 300) {
            setLoading(false); // stop loading.
            throw new Error(response.statusText);
          }
          if (response.status === 200) {
            console.log(response.data);
            setLoading(false); // stop loading.
            notify(response.data.message, `success`);
            redirect("/users");
          }
        })
        .catch((e) => {
          setLoading(false); // stop loading.
          if (e.response) {
            if (e.response.status === 401 || e.response.status === 403) {
              notify(`Unauthorized Request`, `error`);
              localStorage.removeItem("token");
              redirect("/users");
            }
            // other error code (404, 500, etc): no need to log out
          } else {
            notify(`Bad request`, `error`);
            redirect("/users");
          }
        });
    }

    if (callAPI) {
      createUser();
    }
  }, [callAPI]);

  const onSuccess = async (params) => {
    let hash = await ipfs.add(buffer, {
      // get buffer IPFS hash
      pin: true,
      progress: (bytes) => {
        // console.log("File upload progress 1 ", Math.floor(bytes * 100 / (params.image.rawFile.size)))
      },
    });
    setParams({
      name: params.name,
      email: params.email,
      bio: params.bio,
      walletAddress: params.walletAddress,
      profile: hash.path,
      category: params.categories,
      username: params.username,
    });
    setCallAPI(true);
  };

  return loading ? (
    <Loader />
  ) : (
    <SimpleForm save={onSuccess}>
      <ImageInput
        source="image"
        label="Profile Image"
        placeholder="Upload the profile image."
        accept="image/*"
        multiline={false}
        validate={required()}
        onChange={async (file) => {
          fileToArrayBuffer(file).then((data) => {
            setBuffer(file);
          });
        }}
      >
        <ImageField source="src" title="profile image" />
      </ImageInput>
      <TextInput source="name" validate={required()} />
      <TextInput source="username" validate={required()} />
      <TextInput source="email" validate={required()} />
      <TextInput source="walletAddress" validate={required()} />

      <SelectArrayInput
        source="categories"
        choices={categories}
        optionValue="id"
        optionText={(category) => category.categoryName.en}
        validate={required()}
      />

      <TextInput source="bio" validate={required()} />
    </SimpleForm>
  );
};

export default UserCreate;
