import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  ShowButton,
  ImageField,
  ArrayField,
  ReferenceArrayField,
  SingleFieldList,
  Pagination,
  ChipField,
} from "react-admin";
import PopularCreateButton from "../popular-collection/popular-collection.create";

const useStyles = makeStyles({
  imgContainer: {
    "& img": {
      height: "50px",
      width: "50px",
      objectFit: "contain",
    },
  },
});

const collectionRowClick = (id, basePath, record) =>
  record.editable ? "edit" : "show";

const CollectionShowButton = ({ record }) => (
  <ShowButton basePath="/collections" label="Show" record={record} />
);

const CollectionPopularButton = ({ record }) => {
  return <PopularCreateButton record={record} />;
};

const CollectionPagination = (props) => (
  <Pagination rowsPerPageOptions={[]} {...props} />
);

const CollectionList = (props) => {
  const classes = useStyles();

  return (
    <List
      {...props}
      bulkActionButtons={false}
      title="List of Collections"
      perPage={20}
      pagination={<CollectionPagination />}
    >
      <Datagrid>
        <TextField source="id" sortable={false} />
        <ImageField
          source="logo"
          sortable={false}
          label="Image"
          className={classes.imgContainer}
        />
        <TextField source="name" sortable={false} />
        <TextField source="description" sortable={false} />
        <BooleanField source="isActive" sortable={false} />
        <ArrayField source="category">
          <SingleFieldList>
            <ChipField source="categoryName.en" />
          </SingleFieldList>
        </ArrayField>
        <CollectionShowButton source="View" />
        <CollectionPopularButton source="Action" />
      </Datagrid>
    </List>
  );
};

export default CollectionList;
