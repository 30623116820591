import axios from "axios";

// const baseURL = process.env.REACT_APP_BASE_URL_LOCAL;
const baseURL = process.env.REACT_APP_BASE_URL;
// production server
export const api = axios.create({
  baseURL: baseURL,
});

export const apiUrl = baseURL;

// aws s3 bucket confiurations
export const awsRegion = "eu-central-1";
export const awsIdentityPoolId =
  "eu-central-1:949b9487-ed35-4eb3-a2d3-1866e6ad890b";
export const awsBucket = "avangrat-development";
