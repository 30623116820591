import nftABI from "../contractData/abis/nft.json";
import { getCurrentChainContractAddress } from "../helper/contracts";
import { services } from "../services";

var currentContractAddress = getCurrentChainContractAddress("token");

export const web3Actions = {
  getNetworkId,
  getNFTContractInstance,
};

function getNFTContractInstance() {
  const response = services.getContractInstance(nftABI, currentContractAddress);
  return response.then((promise) => {
    if (promise) {
      return promise;
    } else {
      console.log("error in actions  getNFTContractInstance ");
    }
  });
}

function getNetworkId() {
  return () => {
    const response = services.getNetworkId();
    response.then((promise) => {
      if (promise) {
        console.log(promise);
      } else {
        console.log("error in actions  getNetworkId ");
      }
    });
  };
}
