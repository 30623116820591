import * as React from "react";
import { useState, useEffect } from "react";
import {
  List,
  Datagrid,
  TextField,
  ImageField,
  BooleanField,
  ShowButton,
  Pagination,
  TopToolbar,
  CreateButton,
  ExportButton,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import dataProvider from "../../authentication/dataProvider";

const infoRowClick = (id, basePath, record) =>
  record.editable ? "edit" : "show";
const ProfileInfoShowButton = ({ record }) => (
  <ShowButton basePath="/profile-info" label="Show" record={record} />
);
const useStyles = makeStyles({
  imgContainer: {
    "& img": {
      height: "60px",
      width: "60px",
      objectFit: "contain",
      // borderRadius: '50%',
    },
  },
});
const ProfileInfoPagination = (props) => (
  <Pagination rowsPerPageOptions={[]} {...props} />
);

const ProfileInfoList = (props) => {
  const classes = useStyles();
  const [create, setCreate] = useState(true);

  useEffect(() => {
    async function fetchList() {
      const response = await dataProvider.getList("profile-info", {
        pagination: { page: null },
      });
      setCreate(response.data.length > 0 ? false : true);
    }
    fetchList();
  });

  const InfoActionToolbar = (props) => {
    return (
      <TopToolbar>
        {create ? <CreateButton {...props} /> : ""}
        <ExportButton {...props} />
      </TopToolbar>
    );
  };

  return (
    <List
      {...props}
      title="List of Profile Info"
      perPage={20}
      pagination={<ProfileInfoPagination />}
      bulkActionButtons={false}
      actions={<InfoActionToolbar />}
    >
      <Datagrid rowClick={infoRowClick}>
        <TextField source="id" sortable={false} />
        <ImageField
          source="banner.en"
          sortable={false}
          label="English Version Banner"
          className={classes.imgContainer}
        />
        <ImageField
          source="banner.tu"
          sortable={false}
          label="Turkish Version Banner"
          className={classes.imgContainer}
        />
        <TextField source="url" sortable={false} label="url" />
        <BooleanField source="isActive" sortable={false} label="Active" />
        <ProfileInfoShowButton source="View" />
      </Datagrid>
    </List>
  );
};

export default ProfileInfoList;
