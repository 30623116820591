import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  ShowButton,
  Pagination,
  BooleanField,
  ImageField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const PopularCollectionRowClick = (id, basePath, record) =>
  record.editable ? "edit" : "show";
const PopularCollectionShowButton = ({ record }) => (
  <ShowButton basePath="/popular-collection" label="Show" record={record} />
);
const useStyles = makeStyles({
  imgContainer: {
    "& img": {
      height: "60px",
      width: "60px",
      objectFit: "contain",
      // borderRadius: '50%',
    },
  },
});
const PopularCollectionPagination = (props) => (
  <Pagination rowsPerPageOptions={[]} {...props} />
);

const PopularCollectionList = (props) => {
  const classes = useStyles();

  return (
    <List
      {...props}
      title="List of Popular NFTs"
      perPage={20}
      pagination={<PopularCollectionPagination />}
      bulkActionButtons={false}
    >
      <Datagrid rowClick={PopularCollectionRowClick}>
        <TextField source="id" sortable={false} />
        <ImageField
          source="collectionId.logo"
          sortable={false}
          label="Image"
          className={classes.imgContainer}
        />
        <TextField source="collectionId.name" sortable={false} label="Name" />
        <TextField source="ranking" sortable={false} />
        {/* <BooleanField source="isActive" sortable={false} /> */}
        <PopularCollectionShowButton source="View" />
      </Datagrid>
    </List>
  );
};

export default PopularCollectionList;
