import { React, useEffect, useState } from "react";
import { Filter, useTranslate } from "react-admin";
import { makeStyles, Chip } from "@material-ui/core";
import { actions } from "../../actions";

const useQuickFilterStyles = makeStyles((theme) => ({
  chip: {
    marginBottom: theme.spacing(1),
  },
}));

const QuickFilter = ({ label }) => {
  const translate = useTranslate();
  const classes = useQuickFilterStyles();
  return <Chip className={classes.chip} label={translate(label)} />;
};

const UserFilters = (props) => {
  let [roles, setRoles] = useState([]);

  useEffect(() => {
    async function getRoles() {
      const roles = await actions.getRoles();
      setRoles(roles);
    }
    getRoles();
  }, []);

  return (
    <Filter {...props}>
      {roles
        ? roles.map((role, index) => (
            <QuickFilter
              key={index}
              source={role.roleName}
              label={role.roleName}
              defaultValue={role.id}
            />
          ))
        : ""}
    </Filter>
  );
};

export default UserFilters;
