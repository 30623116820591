import { useState, useEffect } from "react";
import {
  SimpleForm,
  TextInput,
  required,
  ImageInput,
  ImageField,
  useRedirect,
  useNotify,
  BooleanInput,
  DeleteButton,
  TopToolbar,
  Toolbar,
  SaveButton,
} from "react-admin";
import fileToArrayBuffer from "file-to-array-buffer";
import dataProvider from "../../authentication/dataProvider";
import { services } from "../../services";
import ipfs from ".././../config/ipfs";
import Loader from "../loading";
import BackButton from "../backButton";

const BannerEdit = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [loading, setLoading] = useState(false);
  const [callAPI, setCallAPI] = useState(false);
  const [params, setParams] = useState({
    id: null,
    url: null,
    status: null,
    banner: { en: null, tu: null },
  });

  useEffect(() => {
    async function fetchBanner() {
      const response = await dataProvider.getOne("banners", { id: props.id });
      setParams({
        id: response.data.id,
        url: response.data.url,
        status: response.data.isActive,
        banner: { en: response.data.banner.en, tu: response.data.banner.tu },
        mobile: { en: response.data.mobile.en, tu: response.data.mobile.tu },
        buffer: { en: null, tu: null },
        mobBuffer: { en: null, tu: null },
      });
    }
    fetchBanner();

    async function updateBanner() {
      const request = services.put(`admin/banner/update/${params.id}`, params);
      request
        .then((response) => {
          setLoading(false); // stop loading.
          if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
          }
          if (response.status === 200) {
            notify(response.data.message, `success`);
            redirect("/banners");
          }
        })
        .catch((e) => {
          setLoading(false); // stop loading.
          if (e.response) {
            if (e.response.status === 401 || e.response.status === 403) {
              notify(`Unauthorized Request`, `error`);
              localStorage.removeItem("token");
              redirect("/banners");
            }
            // other error code (404, 500, etc): no need to log out
          } else {
            notify(`Bad request`, `error`);
            redirect("/banners");
          }
        });
    }

    if (callAPI) {
      updateBanner();
    }
  }, [callAPI]);

  const onSuccess = async (event) => {
    setLoading(true); // start loader
    /* `banner` is the folder name inside these all images are storing */
    let mobile = { en: null, tu: null };
    let banner = { en: null, tu: null };

    if (params.buffer.en) {
      banner.en = await ipfs.add(params.buffer.en, {
        // get buffer IPFS hash
        pin: true,
        progress: (bytes) => {
          // console.log("File upload progress 1 ", Math.floor(bytes * 100 / (params.banner.en.rawFile.size)))
        },
      });
      banner.en = banner.en.path;
    } else {
      banner.en = params.banner.en.substring(
        params.banner.en.lastIndexOf("/") + 1
      );
    }
    if (params.buffer.tu) {
      banner.tu = await ipfs.add(params.buffer.tu, {
        // get buffer IPFS hash
        pin: true,
        progress: (bytes) => {
          // console.log("File upload progress 1 ", Math.floor(bytes * 100 / (params.banner.tu.rawFile.size)))
        },
      });
      banner.tu = banner.tu.path;
    } else {
      banner.tu = params.banner.tu.substring(
        params.banner.tu.lastIndexOf("/") + 1
      );
    }
    if (params.mobBuffer.en) {
      mobile.en = await ipfs.add(params.mobBuffer.en, {
        // get buffer IPFS hash
        pin: true,
        progress: (bytes) => {
          // console.log("File upload progress 3 ", Math.floor(bytes * 100 / (params.mobile.en.rawFile.size)))
        },
      });
      mobile.en = mobile.en.path;
    } else {
      mobile.en = params.mobile.en.substring(
        params.mobile.en.lastIndexOf("/") + 1
      );
    }
    if (params.mobBuffer.tu) {
      mobile.tu = await ipfs.add(params.mobBuffer.tu, {
        // get buffer IPFS hash
        pin: true,
        progress: (bytes) => {
          // console.log("File upload progress 4 ", Math.floor(bytes * 100 / (params.mobile.tu.rawFile.size)))
        },
      });
      mobile.tu = mobile.tu.path;
    } else {
      mobile.tu = params.mobile.tu.substring(
        params.mobile.tu.lastIndexOf("/") + 1
      );
    }
    setParams({
      id: event.id,
      url: event.url,
      status: event.status,
      banner: banner,
      mobile: mobile,
    });
    setCallAPI(true);
  };

  const PreviewImage = ({ record, source }) => {
    if (typeof record == "string") {
      record = {
        [source]: record,
      };
    }
    return <ImageField record={record} source={source} />;
  };

  const BannerActionToolbar = () => (
    <TopToolbar>
      <BackButton color="primary" style={{ marginLeft: "5rem" }} />
    </TopToolbar>
  );

  const BannerEditToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton />
      <DeleteButton />
    </Toolbar>
  );

  return loading ? (
    <Loader />
  ) : params.id ? (
    <>
      <SimpleForm save={onSuccess} toolbar={<BannerEditToolbar />}>
        <BannerActionToolbar />
        <TextInput disabled label="Id" source="id" initialValue={params.id} />
        <ImageInput
          initialValue={params.banner.en}
          source="banner.en"
          label="Image for English"
          placeholder="Upload the image."
          accept="image/*"
          multiline={false}
          validate={required()}
          onChange={async (file) => {
            fileToArrayBuffer(file).then((buffer) => {
              setParams({ ...params, buffer: { ...params.buffer, en: file } });
            });
          }}
        >
          <PreviewImage source="en" />
        </ImageInput>
        <ImageInput
          initialValue={params.banner.tu}
          source="banner.tu"
          label="Image for Turkish"
          placeholder="Upload the image."
          accept="image/*"
          multiline={false}
          validate={required()}
          onChange={async (file) => {
            fileToArrayBuffer(file).then((buffer) => {
              setParams({ ...params, buffer: { ...params.buffer, tu: file } });
            });
          }}
        >
          <PreviewImage source="src" />
        </ImageInput>

        {/* mobile */}
        <ImageInput
          initialValue={params.mobile.tu}
          source="mobile.tu"
          label="Banner for Mobile in Turkish"
          placeholder="Upload the image."
          accept="image/*"
          multiline={false}
          validate={required()}
          onChange={async (file) => {
            fileToArrayBuffer(file).then((buffer) => {
              setParams({
                ...params,
                mobBuffer: { ...params.mobBuffer, tu: file },
              });
            });
          }}
        >
          <PreviewImage source="src" />
        </ImageInput>

        <ImageInput
          initialValue={params.mobile.en}
          source="mobile.en"
          label="Banner for Mobile in English"
          placeholder="Upload the image."
          accept="image/*"
          multiline={false}
          validate={required()}
          onChange={async (file) => {
            fileToArrayBuffer(file).then((buffer) => {
              setParams({
                ...params,
                mobBuffer: { ...params.mobBuffer, en: file },
              });
            });
          }}
        >
          <PreviewImage source="src" />
        </ImageInput>

        <TextInput
          initialValue={params.url}
          source="url"
          onChange={(e) => {
            params.url = e.target.value;
          }}
          validate={required()}
        />
        <BooleanInput source="status" initialValue={params.status} />
        <DeleteButton />
      </SimpleForm>
    </>
  ) : (
    ""
  );
};

export default BannerEdit;
