import { useState, useEffect } from "react";
import {
  SimpleForm,
  TextInput,
  required,
  ImageInput,
  ImageField,
  useRedirect,
  useNotify,
  BooleanInput,
  DeleteButton,
  TopToolbar,
  Toolbar,
  SaveButton,
} from "react-admin";
import fileToArrayBuffer from "file-to-array-buffer";
import dataProvider from "../../authentication/dataProvider";
import { services } from "../../services";
import ipfs from "../../config/ipfs";
import Loader from "../loading";
import BackButton from "../backButton";

const InfoEdit = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [loading, setLoading] = useState(false);
  const [callAPI, setCallAPI] = useState(false);
  const [buffer, setBuffer] = useState({ en: null, tu: null });
  const [mobBuffer, setMobBuffer] = useState({ en: null, tu: null });
  const [params, setParams] = useState({
    id: null,
    url: null,
    status: null,
    button_text: null,
    button_url: null,
    banner: null,
    mobile: null,
  });

  useEffect(() => {
    async function fetchInfo() {
      const response = await dataProvider.getOne("info", { id: props.id });
      setParams({
        id: response.data.id,
        button_url: response.data.button_url,
        url: response.data.url,
        status: response.data.isActive,
        button_text: response.data.button_text
          ? response.data.button_text
          : { en: null, tu: null },
        banner: { en: response.data.banner.en, tu: response.data.banner.tu },
        mobile: { en: response.data.mobile.en, tu: response.data.mobile.tu },
      });
    }
    fetchInfo();

    async function updateInfo() {
      const request = services.put(`admin/info/update/${params.id}`, params);
      request
        .then((response) => {
          setLoading(false); // stop loading.
          if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
          }
          if (response.status === 200) {
            notify(response.data.message, `success`);
            redirect("/info");
          }
        })
        .catch((e) => {
          setLoading(false); // stop loading.
          if (e.response) {
            if (e.response.status === 401 || e.response.status === 403) {
              notify(`Unauthorized Request`, `error`);
              localStorage.removeItem("token");
              redirect("/info");
            }
            // other error code (404, 500, etc): no need to log out
          } else {
            notify(`Bad request`, `error`);
            redirect("/info");
          }
        });
    }

    if (callAPI) {
      updateInfo();
    }
  }, [callAPI]);

  const onSuccess = async (event) => {
    setLoading(true); // start loader
    let { banner, mobile } = event;
    let banner_ = {
      en: params.banner.en.substring(params.banner.en.lastIndexOf("/") + 1),
      tu: params.banner.tu.substring(params.banner.tu.lastIndexOf("/") + 1),
    };
    let mobile_ = {
      en: params.mobile.en.substring(params.mobile.en.lastIndexOf("/") + 1),
      tu: params.mobile.tu.substring(params.mobile.tu.lastIndexOf("/") + 1),
    };
    if (buffer.en) {
      banner_.en = await ipfs.add(buffer.en, {
        // get buffer IPFS hash
        pin: true,
        progress: (bytes) => {
          // console.log("File upload progress 1 ", Math.floor(bytes * 100 / (banner.en.rawFile.size)))
        },
      });
      banner_.en = banner_.en.path;
    }
    if (buffer.tu) {
      banner_.tu = await ipfs.add(buffer.tu, {
        // get buffer IPFS hash
        pin: true,
        progress: (bytes) => {
          // console.log("File upload progress 1 ", Math.floor(bytes * 100 / (banner.tu.rawFile.size)))
        },
      });
      banner_.tu = banner_.tu.path;
    }
    if (mobBuffer.en) {
      mobile_.en = await ipfs.add(mobBuffer.en, {
        // get buffer IPFS hash
        pin: true,
        progress: (bytes) => {
          // console.log("File upload progress 1 ", Math.floor(bytes * 100 / (event.mobile.en.rawFile.size)))
        },
      });
      mobile_.en = mobile_.en.path;
    }
    if (mobBuffer.tu) {
      mobile_.tu = await ipfs.add(mobBuffer.tu, {
        // get buffer IPFS hash
        pin: true,
        progress: (bytes) => {
          // console.log("File upload progress 1 ", Math.floor(bytes * 100 / (mobile.tu.rawFile.size)))
        },
      });
      mobile_.tu = mobile_.tu.path;
    }
    setParams({
      ...params,
      status: event.status,
      url: event.url,
      button_url: event.button_url,
      button_text: event.button_text,
      banner: banner_,
      mobile: mobile_,
    });
    setCallAPI(true);
  };

  const PreviewImage = ({ record, source }) => {
    if (typeof record == "string") {
      record = {
        [source]: record,
      };
    }
    return <ImageField record={record} source={source} />;
  };

  const InfoActionToolbar = () => (
    <TopToolbar>
      <BackButton color="primary" style={{ marginLeft: "5rem" }} />
    </TopToolbar>
  );

  const InfoEditToolbar = (props) => (
    <Toolbar {...props}>
      <SaveButton />
      <DeleteButton />
    </Toolbar>
  );

  return loading ? (
    <Loader />
  ) : params.id ? (
    <>
      <SimpleForm save={onSuccess} toolbar={<InfoEditToolbar />}>
        <InfoActionToolbar />
        <TextInput disabled label="Id" source="id" initialValue={params.id} />
        <ImageInput
          initialValue={params.banner.en}
          source="banner.en"
          label="Desktop Image for English"
          placeholder="Upload desktop the image for english"
          accept="image/*"
          multiline={false}
          validate={required()}
          onChange={async (file) => {
            fileToArrayBuffer(file).then((data) => {
              setBuffer({ ...buffer, en: file });
            });
          }}
        >
          <PreviewImage source="en" title="title" />
        </ImageInput>
        <ImageInput
          initialValue={params.banner.tu}
          source="banner.tu"
          label="Desktop Image for Turkish"
          placeholder="Upload desktop the image for turkish"
          accept="image/*"
          multiline={false}
          validate={required()}
          onChange={async (file) => {
            fileToArrayBuffer(file).then((data) => {
              setBuffer({ ...buffer, tu: file });
            });
          }}
        >
          <PreviewImage source="src" title="title" />
        </ImageInput>

        <ImageInput
          initialValue={params.mobile.en}
          source="mobile.en"
          label="Mobile Image for English"
          placeholder="Upload mobile the image for english"
          accept="image/*"
          multiline={false}
          validate={required()}
          onChange={async (file) => {
            fileToArrayBuffer(file).then((buffer) => {
              setMobBuffer({ ...mobBuffer, en: file });
            });
          }}
        >
          <PreviewImage source="en" title="title" />
        </ImageInput>
        <ImageInput
          initialValue={params.mobile.tu}
          source="mobile.tu"
          label="Mobile Image for Turkish"
          placeholder="Upload mobile the image for turkish."
          accept="image/*"
          multiline={false}
          validate={required()}
          onChange={async (file) => {
            fileToArrayBuffer(file).then((buffer) => {
              setMobBuffer({ ...mobBuffer, tu: file });
            });
          }}
        >
          <PreviewImage source="src" title="title" />
        </ImageInput>

        <TextInput
          initialValue={params.url}
          source="url"
          validate={required()}
          label="Banner URL"
        />
        {params.button_text && (
          <>
            <TextInput
              initialValue={params.button_text.en}
              validate={required()}
              source="button_text.en"
              label="Button Text For English"
            />{" "}
            <br />
            <TextInput
              initialValue={params.button_text.tu}
              validate={required()}
              source="button_text.tu"
              label="Button Text For Turkish"
            />
          </>
        )}
        {params.button_url && (
          <TextInput initialValue={params.button_url} source="button_url" />
        )}
        <BooleanInput source="status" initialValue={params.status} />
        <DeleteButton />
      </SimpleForm>
    </>
  ) : (
    ""
  );
};

export default InfoEdit;
