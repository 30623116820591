import * as React from "react";
import { Card, CardContent, CardHeader } from "@material-ui/core";

import { SimpleForm, BooleanInput, useNotify, NumberInput } from "react-admin";
import { useState, useEffect } from "react";
import { actions } from "../../actions";

const Dashoard = (props) => {
  const notify = useNotify();
  const [state, setState] = useState();
  const [params, setParams] = useState(false);
  const [qr, setQr] = useState();
  const [googleCode, setGoogleCode] = useState();
  const [isVerifyActive, setIsVerifyActive] = useState(false);
  useEffect(() => {
    actions.postQr().then((res) => {
      if (res && res.data && res.data.data && res.data.data.qr) {
        setQr(res.data.data.qr);
      }
    });
  }, [isVerifyActive]);

  useEffect(() => {
    actions.checkVerified().then((res) => {
      setIsVerifyActive(res.data.data.verified);
    });
  }, []);
  useEffect(() => {
    async function getSwitches() {
      const tabs = await actions.getSwitches();
      setState({ tabs: tabs });
    }
    getSwitches();

    async function updateSwitches() {
      const response = await actions.updateSwitches(params.id, {
        isActive: params.isActive,
      });
      notify(response.message, `success`);
      setState((prevState) => ({
        ...prevState,
        tabs: prevState.tabs.map((tab) => ({
          ...tab,
          isActive: tab.id === params.id ? params.isActive : tab.isActive,
        })),
      }));
    }

    if (params) {
      updateSwitches();
    }
  }, [params]);

  const onChange = (isActive, id) => {
    setParams({ id: id, isActive: isActive });
  };

  return (
    <Card>
      <CardHeader title="Welcome to the administration" />
      <CardContent>This is the Avan Gart admin panel...</CardContent>
      <br />
      <SimpleForm toolbar={null}>
        {state
          ? state.tabs.map((tab, index) => {
              return (
                <BooleanInput
                  key={index}
                  label={tab.isActive ? `${tab.name}` : `Enable ${tab.name}`}
                  id={tab.id}
                  name={tab.id}
                  defaultValue={tab.isActive ? true : false}
                  source="isActive"
                  onChange={(event) => onChange(event, tab.id)}
                />
              );
            })
          : ""}
        {!isVerifyActive && <img src={qr}></img>}
        {!isVerifyActive && (
          <input
            type="text"
            value={googleCode}
            onChange={(e) => setGoogleCode(e.target.value)}
          />
        )}
      </SimpleForm>
      {!isVerifyActive && (
        <button
          onClick={() => {
            actions.sendGoogleCode(googleCode);
          }}
        >
          send
        </button>
      )}
    </Card>
  );
};

export default Dashoard;
