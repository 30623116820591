import { services } from "../services";

export const adminActions = {
  getSwitches,
  updateSwitches,
  postQr,
  sendGoogleCode,
  checkVerified,
};

function checkVerified() {
  return services.post(`/admin/2fa/check-verified`, {});
}

function sendGoogleCode(googleCode) {
  const token = localStorage.getItem("token");

  const requestBody = {
    ["x-auth-token"]: token,
    token: googleCode,
  };
  return services.post(`/admin/2fa/verify`, requestBody);
}

function postQr() {
  const token = localStorage.getItem("token");
  return services.post(`/admin/2fa/register`, { ["x-auth-token"]: token });
}

function getSwitches() {
  const response = services.get(`/admin/dashboard/list`);
  return response.then((promise) => {
    if (promise) {
      return promise.data.data;
    } else {
      console.log("error in actions get switches ");
    }
  });
}

function updateSwitches(id, params) {
  const response = services.put(`admin/dashboard/update/${id}`, params);
  return response.then((promise) => {
    if (promise) {
      return promise.data;
    } else {
      console.log("error in actions update switches ");
    }
  });
}
