import * as React from "react";
import { useState } from "react";
import { useLogin, useNotify, Notification, defaultTheme } from "react-admin";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import Paper from "@mui/material/Paper";
import { TextField } from "@material-ui/core";
import styled from "styled-components";
import Button from "@mui/material/Button";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: radial-gradient(
    circle,
    rgba(238, 174, 202, 1) 0%,
    rgba(148, 187, 233, 1) 100%
  );
`;

const FormContainer = styled.form`
  padding: 20px 20px 150px 20px;
`;

const CustomizeTextField = styled(TextField)`
  margin-bottom: 10px !important;

  & > input::after {
    border-bottom: 2px solid #bb29a0 !important;
  }
`;

const CustomizeButton = styled(Button)`
  margin-top: 20px !important;
  background: rgba(79, 138, 209, 1) !important; /* fallback for old browsers */
`;
const MyLoginPage = ({ theme }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const login = useLogin();
  const notify = useNotify();
  const submit = (e) => {
    e.preventDefault();
    // will call authProvider.login({ email, password })
    login({ username, password, token }).catch(() =>
      notify("Invalid email or password")
    );
  };

  return (
    <ThemeProvider theme={createMuiTheme(defaultTheme)}>
      <Container>
        <Paper
          elevation={3}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            "& > :not(style)": {
              m: 1,
              width: 200,
              height: 128,
            },
            width: "fit-content",
          }}
        >
          <FormContainer
            onSubmit={submit}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <CustomizeTextField
              id="standard-basic"
              label="Username"
              variant="standard"
              name="username"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <CustomizeTextField
              id="standard-basic"
              label="Password"
              variant="standard"
              name="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <CustomizeTextField
              id="standard-basic"
              label="Token"
              variant="standard"
              name="token"
              type="password"
              value={token}
              onChange={(e) => setToken(e.target.value)}
            />
            <CustomizeButton onClick={submit} variant="contained">
              LOGIN
            </CustomizeButton>
          </FormContainer>
        </Paper>
      </Container>
      <Notification />
    </ThemeProvider>
  );
};

export default MyLoginPage;
