import * as React from "react";
import { useState, useEffect } from "react";
import {
  Show,
  SimpleShowLayout,
  useRedirect,
  useNotify,
  TextField,
  DateField,
  useRefresh,
  TopToolbar,
  EditButton,
  BooleanField,
  ImageField,
} from "react-admin";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import BackButton from "../backButton";
import { services } from "../../services";
import Loader from "../loading";

const useStyles = makeStyles({
  imgContainer: {
    "& img": {
      height: "150px",
      width: "150px",
      objectFit: "contain",
    },
  },
});

const CategoryShow = (props) => {
  const [apiCall, setAPICalled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const [categoryId, setCategoryId] = useState(null);

  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  const classes = useStyles();

  useEffect(() => {
    async function disabledCategory() {
      setLoading(true); // start loading.
      const params = { status: status };
      const request = services.put("category/update/" + categoryId, params);
      request
        .then((response) => {
          if (response.status < 200 || response.status >= 300) {
            setLoading(true); // start loading.
            throw new Error(response.statusText);
          }
          if (response.status === 200) {
            setLoading(true); // start loading.
            notify(response.data.message, `success`);
            redirect("/categories");
          }
        })
        .catch((e) => {
          setLoading(true); // start loading.
          if (e.response) {
            if (e.response.status === 401 || e.response.status === 403) {
              notify(`Unauthorized Request`, `error`);
              localStorage.removeItem("token");
              refresh();
            }
            // other error code (404, 500, etc): no need to log out
          } else {
            notify(`Bad request`, `error`);
            refresh();
          }
        });
    }

    if (apiCall) {
      disabledCategory();
    }
  }, [apiCall]);

  const CategoryDisableClick = ({ _id, isActive }) => {
    setCategoryId(_id);
    setAPICalled(true);
    setStatus(isActive ? false : true);
  };

  const CategoryShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
      <BackButton
        // variant='outlined'
        color="primary"
        style={{ marginLeft: "5rem" }}
      />
      <EditButton basePath={basePath} record={data} />
      {/* Add your custom actions */}
      <Button color="primary" onClick={() => CategoryDisableClick(data)}>
        {data ? (data.isActive ? "Disable" : "Enable") : "--"}
      </Button>
    </TopToolbar>
  );

  return loading ? (
    <Loader />
  ) : (
    <Show actions={<CategoryShowActions />} {...props}>
      <SimpleShowLayout>
        <TextField source="id" sortable={false} />
        <ImageField
          source="image"
          sortable={false}
          className={classes.imgContainer}
        />
        <TextField
          source="categoryName.en"
          sortable={false}
          label="English Version Banner"
        />
        <TextField
          source="categoryName.tu"
          sortable={false}
          label="Turkish Version Banner"
        />
        <DateField source="createdAt" sortable={false} />
        <BooleanField source="isActive" sortable={false} />
      </SimpleShowLayout>
    </Show>
  );
};

export default CategoryShow;
