import * as React from "react";
import {
  Show,
  useRedirect,
  useNotify,
  SimpleShowLayout,
  TextField,
  DateField,
  ImageField,
  BooleanField,
  TopToolbar,
  DeleteWithConfirmButton,
  EditButton,
} from "react-admin";
import BackButton from "../backButton";

const ProfileInfoShow = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const CustomShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
      <BackButton color="primary" style={{ marginLeft: "5rem" }} />
      <EditButton basePath={basePath} record={data} />
      {data ? (
        <DeleteWithConfirmButton
          undoable={false}
          onSuccess={onSuccess}
          onFailure={onFailure}
          basePath={basePath}
          record={data}
          resource={resource}
        />
      ) : (
        ""
      )}
    </TopToolbar>
  );

  const onSuccess = () => {
    notify(`Profile Info deleted successfully`, `success`);
    redirect("/profile-info");
  };

  const onFailure = (error) => {
    console.log(error);
    notify(`Profile Info can not be delete`, `warning`);
    redirect("/profile-info");
  };

  return (
    <Show {...props} actions={<CustomShowActions />}>
      <SimpleShowLayout>
        <TextField source="id" sortable={false} />
        <TextField source="url" sortable={false} />
        <BooleanField source="isActive" sortable={false} />
        <ImageField source="banner.en" sortable={false} />
        <ImageField source="banner.tu" sortable={false} />
        <ImageField source="mobile.en" sortable={false} />
        <ImageField source="mobile.tu" sortable={false} />
        <DateField source="createdAt" sortable={false} />
      </SimpleShowLayout>
    </Show>
  );
};

export default ProfileInfoShow;
